/**
 * Loader to be used across the site
 * Use this variable
 */

$loader-svg: '../images/loaders/invert/ball-triangle.svg' !default;
$loader-gif: '../images/loaders/invert/ball-triangle.gif' !default;
$loader-bg: rgba($white, .9)  !default;
$loader-size: 160px !default;
$loader-show-text: false !default;
$loader-z: z('heavenly') !default;