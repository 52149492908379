.cms-page-view:not([class*=pagebuilder]) .column.main {
    .pagebuilder-column [data-content-type=products] .block.widget .products-grid .widget-product-grid.product-items .product-item {
        width: 25%;
    }

    .pagebuilder-banner-wrapper {
        display: flex;
    }

    .sector-top-banner {
        border-bottom: #b0aa00 solid 10px;

        .pagebuilder-overlay {
            display: flex;

            .pagebuilder-collage-content {
                align-self: center;

                [data-element=content] {
                    background: #fff;
                    color: #000;
                    padding: 10px;
                    border: 10px solid #b0aa00;
                    border-width: 0 0 0 10px;

                    h1, h2, h3, h4, h5, p {
                        margin-bottom: 0;
                    }
                }
            }

        }

    }

    .sector-block {

        .pagebuilder-overlay {
            display: flex;

            .pagebuilder-collage-content {
                align-self: flex-end;

                [data-element=content] {
                    background: #000;
                    color: #fff;
                    padding: 10px;
                    border: 10px solid #b0aa00;
                    border-width: 0 0 0 10px;

                    h1, h2, h3, h4, h5, p,a  {
                        margin-bottom: 0;
                        padding-left:10px;
                    }
                }
            }
        }

        &.left {
            [data-element=content] {
                float: left;
            }
        }

        &.right {
            [data-element=content] {
                float: right;
            }
        }
    }
}
