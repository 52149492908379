$success-color: map-get($theme-colors, 'primary');

.message {
    &.success {
        background-color: $success-color;
        color: $white;
        border-color: darken($success-color, 10%);

        &:before {
            font-size: 32px;
            color: $white;
        }
    }

    a {
        color: inherit;
        opacity: 1;
        transition: opacity 0.3ms ease-in-out;

        &:hover {
            opacity: 0.6;
            transition: opacity 0.3s ease-in-out;
        }
    }
}
