h1,h2,h3,h4,h5,h6, .block .title {
    @include font-family-heading();
}

body .footer.links [data-content-type="heading"] {
    @include font-family-condensed();
}

body .ntrn__menu>li>a,
body .ntrn__sub--level1 .ntrn__cols .ntrn__col>.ntrn__item.ntrn__item--hassub>.ntrn__link {
    @include respond-to-up($tweakpoint-nav-toggle) {
        @include font-family-condensed();
    }
}

body .ntrn__sub--level1 .ntrn__cols .ntrn__col>.ntrn__item.ntrn__item--hassub>.ntrn__link {
    @include respond-to-up($tweakpoint-nav-toggle) {
        font-size: 25px;
    }
}

h1 {
    font-size: 32px;
    @include respond-to-up($tweakpoint-nav-toggle) {
        font-size: 48px;
    }
}

h2 {
    font-size: 26px;
    @include respond-to-up($tweakpoint-nav-toggle) {
        font-size: 36px;
    }
}

h3 {
    font-size: 22px;
    @include respond-to-up($tweakpoint-nav-toggle) {
        font-size: 28px;
    }
}

h4 {
    font-size: 18px;
}
