.lazyload,
.lazyloading {
  opacity: 0;

  + .image-skeleton {
    display: block;
  }
}

[data-src] {
  @extend %u-transform-fade-transition;
}

.lazyloaded {
  opacity: 1;

  + .image-skeleton {
    display: none;
  }
}

.image-skeleton {
  background-color: #e2e2e2;

  &:after {
    content: '';
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent
    );
    animation: shimmer 1.5s infinite;
  }
}
.image-skeleton,
.image-skeleton:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
