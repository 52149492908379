[role="button"],
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  box-sizing: border-box;
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
     -moz-user-select: none;
      -ms-user-select: none;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

[role="button"] {
  display: inline-block;
  color: inherit;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
}

div[class^='addthis_inline_'] [role="button"]{
  -webkit-appearance: none;
}
