/**
 * Form Elements config
 */

$input-padding-y: spacers(3) * 0.75 !default;
$input-padding-x: $input-padding-y * 2 !default;
$input-margin-y: spacers(4) !default;

$input-border-color: $gray-200 !default;
$input-border-width: 1px !default;
$input-border-focus-color: theme-color('neutral-subtle') !default;
$input-border-focus-highlight: theme-color('neutral') !default;
$input-border-focus-highlight-width: 4px !default;
$input-border-radius: 0 !default;

$input-placeholder-color: rgba($body-color, 0.7) !default;

$input-bg: $white !default;
$input-bg-active: $white !default;
$input-bg-focus: $white !default;
$input-validation-font-size: $small-font-size !default;

$input-color: $body-color !default;
$input-color-focus: $body-color !default;
$input-error-color: theme-color('negative') !default;
$input-valid-color: theme-color('positive') !default;
$font-size-base-px: strip-unit($font-size-base) * 16px;

$input-select-arrow: '../images/select-arrow.svg' !default;

$checkbox-padding: spacers(1) !default;
$checkbox-spacing-x: spacers(2) !default;
$checkbox-not-active-color: $text-muted !default;

$radio-size: 26px !default;
$radio-spacing-x: spacers(2) !default;
$radio-not-active-color: $text-muted !default;
$radio-active-color: $text-muted !default;

$float-labels: () !default;

$float-labels-defaults: (
        base-height             : $font-size-base-px * 1.5,
        base-padding            : strip-unit($input-padding-y) * 16px,
        border-radius           : $input-border-radius,
        border-width            : $input-border-width,
        color-background        : $input-bg,
        color-background-active : $input-bg-active,
        color-background-focus  : $input-bg-focus,
        color-border            : $input-border-color,
        color-border-active     : $input-border-focus-color,
        color-border-focus      : $input-border-focus-color,
        color-border-focus-highlight: $input-border-focus-highlight,
        width-border-focus-highlight: $input-border-focus-highlight-width,
        color-placeholder       : $input-placeholder-color,
        color-required          : theme-color('negative'),
        color-text              : $input-color,
        color-text-focus        : $input-color-focus,
        line-height             : $line-height-base,
        font-size               : $font-size-base-px,
        font-size-small         : $font-size-base-px * 0.75,
        font-weight             : $font-weight-bold,
        parent                  : '',
        prefix                  : 'fl-',
        transition-easing       : $transition-timing-sharp,
        transition-speed        : $transition-duration-ease-out,
);

$form-container-max-width: 700px;