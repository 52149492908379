.filter-options,
.choice {
  input[type="checkbox"] {
    @extend %u-sr-only;

    & + label {
      @extend %u-flex-row-xcenter-ycenter;
      @include ms-icon('check');
      justify-content: flex-start;
      max-width: 100%;
      color: $checkbox-not-active-color;

      &:before {
         padding: $checkbox-padding;
         margin-right: $checkbox-spacing-x;
         border: 1px solid $input-border-color;
         background: $input-bg;
         color: rgba($input-color, 0);
      }

      @include hover-focus {
        color: $input-color;

        &:before {
          background: $input-bg-focus;
          border-color: $input-border-focus-color;
        }
      }
    }

    &:checked + label {
      color: $input-color;

      &:before {
        background: $input-bg-active;
        color: $input-color;
      }
    }
  }
}