/*
 * Form specific styles
 */

.form-orders-search {
  max-width: 600px;
  margin: spacers(3) auto;
}

.block-orders-returns .block-title {
  text-align: center;
}

.g-recaptcha + .field {
  display: none;
}
