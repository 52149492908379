.password-strength-meter {
  color: $text-muted;
  font-size: $font-size-xs;
  display: block;
  position: relative;
  margin-top: spacers(2);

  @include respond-to-down(md) {
    transform: scale(0.8) translateX(-30px);
  }

  &:before,
  &:after {
    @extend %u-bg-color-transition;
    @include absolute(4px, auto, auto, 0);
    @include size($password-meter-width, $password-meter-height);
    content: '';
    background: $gray-300;
    left: $password-left-offset;
  }

  &:before {
    .password-weak & {
      background: $password-meter-weak-color;
    }

    .password-medium & {
      background: $password-meter-medium-color;
    }

    .password-strong & {
      background: $password-meter-strong-color;
    }

    .password-very-strong & {
      background: $password-meter-very-strong-color;
    }
  }

  &:after {
    left: $password-left-offset + $password-meter-width + $password-meter-spacing-x;

    .password-medium & {
      background: $password-meter-medium-color;
    }

    .password-strong & {
      background: $password-meter-strong-color;
    }

    .password-very-strong & {
      background: $password-meter-very-strong-color;
    }
  }


  [data-role='password-strength-meter-label'] {
    display: block;
    vertical-align: top;
    //text-indent: (($password-meter-width + $password-meter-spacing-x) * 4.2 );
    text-indent: 0;

    .password-weak & {
      color: $password-meter-weak-color;
    }

    .password-medium & {
      color: $password-meter-medium-color;
    }

    .password-strong & {
      color: $password-meter-strong-color;
    }

    .password-very-strong & {
      color: $password-meter-very-strong-color;
    }

    &:before,
    &:after {
      @extend %u-bg-color-transition;
      @include absolute(3px, auto, auto, 0);
      @include size($password-meter-width, $password-meter-height);
      content: '';
      background: $gray-300;
      left: (($password-meter-width + $password-meter-spacing-x) * 2 ) + $password-left-offset;
    }

    &:before {
      .password-strong & {
        background: $password-meter-strong-color;
      }

      .password-very-strong & {
        background: $password-meter-very-strong-color;
      }
    }

    &:after {
      left: (($password-meter-width + $password-meter-spacing-x) * 3 ) + $password-left-offset;

      .password-very-strong & {
        background: $password-meter-very-strong-color;
      }
    }
  }


}
