/**
 * Input element styles
 */

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="file"],
input[type="number"],
input[type="time"],
input[type="datetime-local"],
select,
input[type="password"] {
  padding: $input-padding-y $input-padding-x;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;

  &:focus {
    border-color: $input-border-focus-color;
  }

  field._error &,
  &.mage-error {
    animation: u-animation-shake $transition-duration $transition-timing-ease-in;
    animation-fill-mode: both;
    border-color: $input-error-color;
  }

  &.valid {
    border-color: $input-valid-color;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: $white url(#{$input-select-arrow}) no-repeat 100% 45%;
  background-size: 50px 50px;
  padding-right: 50px;
  text-indent: .01em;
  box-sizing: border-box;
  vertical-align: baseline;
}

textarea {
  &.mage-error {
    animation: u-animation-shake $transition-duration $transition-timing-ease-in;
    animation-fill-mode: both;
    border-color: $input-error-color !important;
  }

  &.valid {
    border-color: $input-valid-color !important;
  }
}

.control > input[type="text"],
.control > input[type="email"],
.control > input[type="password"],
.control > input[type="tel"],
.control > input[type="time"],
.control > input[type="number"],
.control > input[type="file"],
.control > input[type="datetime-local"],
.control > select,
.control > textarea {
  width: 100%;
}

//Input Place holder styles (Global)

@include placeholder {
  color: $input-placeholder-color;
}

div.field-error,
div.mage-error {
  padding: $input-padding-x / 2 0;
  font-size: $input-validation-font-size;
  background: transparent;
  color: $input-error-color;
  text-align: left;
  width: 100%;
  animation: u-animation-slide-down-small $transition-duration $transition-timing-ease-in;
  animation-delay: 0.5s;
  animation-fill-mode: both;
}

// Field
form .field,
form .captcha > .fl-wrap{
  margin-bottom: $input-margin-y;
}

.captcha-image.control {
  @extend %u-flex-col-xcenter-ycenter;
  @include ms-icon('arrow-up', $input-margin-y);
  position: relative;
  justify-content: flex-start;
  flex-wrap: wrap;

  &:before {
    @include absolute($input-margin-y * -1, auto, auto, 50%);
    margin-top: 2px;
    color: darken($input-border-color, 20%);
    transform: translateX(-50%);

    @include respond-to-up(md) {
      left: 95px;
      transform: translateX(0%);
    }
  }

  @include respond-to-up(md) {
    flex-direction: row;
  }

  .captcha-img {
    margin-right: spacers(3);
    border: $input-border-width solid darken($input-border-color, 40%);
  }
}

.fl-has-focus + .nested {
  .captcha-img {
    border-color: $input-border-focus-highlight;
  }
}

// Floating label
.fl-form label.fl-label {
  text-transform: uppercase;
}

.field-tooltip {
  padding: spacers(2) spacers(1) spacers(1) spacers(2);
  background: theme-color('caution-subtle');
  margin-top: spacers(2);
  margin-bottom: spacers(2);
  font-size: $font-size-xs;
  border: 1px solid $input-border-color;

  .field-tooltip-action {
    display: none;
  }

  .field-tooltip-content {
    @include ms-icon('info', 18px);

    &:before {
      display: inline-block;
      vertical-align: middle;
      margin-right: spacers(2);
      position: relative;
      top: -1px;
    }
  }
}

[name="shippingAddress.street.1"] {
  .label {
    display: none;
  }
}

[name="shippingAddress.street.0"].field {
  margin-bottom: spacers(2);
}

.admin__control-fields legend.label {
  font-size: $font-size-base;
  font-weight: normal;
  text-transform: uppercase;
}

// Hide placeholders with null value globally
//
input[placeholder="null"]::placeholder {
  color: rgba(255, 255, 255, 0) !important;
  opacity: 0;
}
input[placeholder="null"]::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0) !important;
  opacity: 0;
}

input[placeholder="null"]::-moz-placeholder {
  color: rgba(255, 255, 255, 0) !important;
  opacity: 0;
}

input[placeholder="null"]:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0) !important;
  opacity: 0;
}

.fl-form label.fl-label:empty {
  display: none;
}

// Number Type Input
// remove arrows from number type input fields globally
// hides number spinner from showing on firefox
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
