@keyframes u-animation-fade  {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes u-animation-spin  {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

@keyframes u-animation-ken-burns  {
  from {transform:scale(1.0);}
  to {transform:scale(1.3);}
}

@keyframes u-animation-scale-down  {
  from {transform-origin: 50% 0%; transform:scaleY(0);}
  to {transform-origin: 50% 0%; transform:scale(1.0);}
}

@keyframes u-animation-slide-up  {
  from {transform-origin: 50% 0%; transform:translateY(180px); opacity: 0;}
  to {transform-origin: 50% 0%; transform:translateY(0%); opacity: 1;}
}

@keyframes u-animation-slide-up-small  {
  from {transform-origin: 50% 0%; transform:translateY(90%); opacity: 0;}
  to {transform-origin: 50% 0%; transform:translateY(0%); opacity: 1;}
}

@keyframes u-animation-slide-down  {
  from {transform-origin: 50% 0%; transform:translateY(-180px); opacity: 0;}
  to {transform-origin: 50% 0%; transform:translateY(0%); opacity: 1;}
}

@keyframes u-animation-slide-down-small  {
  from {transform-origin: 50% 0%; transform:translateY(-70%); opacity: 0;}
  to {transform-origin: 50% 0%; transform:translateY(0%); opacity: 1;}
}

@keyframes u-animation-slide-down-xsmall  {
  from {transform-origin: 50% 0%; transform:translateY(-20%); opacity: 0;}
  to {transform-origin: 50% 0%; transform:translateY(0%); opacity: 1;}
}

@keyframes u-animation-shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}