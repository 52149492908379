/**
 * Promotional Block widget styles
 * for CtiDigital/Widgets module
 *
 */
.promotional-block {
  display: inline-block;
  width: 100%;
  padding: $promotional-block-padding-y $promotional-block-padding-x;
  margin: $promotional-block-spacing;

  @include respond-to-up(lg) {
    padding: $promotional-block-padding-y-lg $promotional-block-padding-x-lg;
  }

  // Resets
  a {
    text-decoration: none;
  }

  &__media {
    margin-bottom: $promotional-media-mb;

    picture,
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__content {}

  // Modes
  &--light {
    background: $promotional-block-light-bg;
    color: $promotional-block-light-color;
  }

  &--dark {
    background: $promotional-block-dark-bg;
    color: $promotional-block-dark-color;

    a:not(.button-base) {
      color: $promotional-block-dark-color;
    }
  }

  &--image-as-bg {
    position: relative;

    .promotional-block__media {
      @include absolute(0, 0);
      @include size(100%);
      z-index: z('below');
      margin: 0;
    }

    .promotional-block__content {
      position: relative;
      width: 100%;
      z-index: z('above');
    }

    picture,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // Positions
  &--center {

  }

  &--left {

  }

  &--right {

  }

  &--bottom-center {

  }

  &--bottom-left {

  }

  &--bottom-right {

  }

  // Text Alignment
  &--text-center {
    text-align: center;

    .button-base {
      margin-left: auto;
      margin-right: auto;
    }

    &.promotional-block--image-as-bg {
      .promotional-block__media {
        @include scrim();
        position: absolute;
      }
    }
  }

  &--text-left {
    text-align: left;

    &.promotional-block--image-as-bg {
      .promotional-block__media {
        @include scrim(left);
        position: absolute;
      }
    }
  }

  &--text-right {
    text-align: right;

    &.promotional-block--image-as-bg {
      .promotional-block__media {
        @include scrim(right);
        position: absolute;
      }
    }

    .button-base {
      margin-left: auto;
    }
  }
}

.promotional-block-content {
  &__heading {
    margin-bottom: $promotional-heading-mb;
  }

  &__description {
    margin-bottom: $promotional-desc-mb;
  }
}

.promotional-block-link-wrapper {
  display: inline-block;
}