.yt-video {
  position: relative;

  &--16-9 {
    @include aspect-ratio(16, 9);
  }

  &--4-3 {
    @include aspect-ratio(4, 3);
  }

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: z('above');
  }
}