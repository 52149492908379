@if $button-styles-override == true {
    /**
     * Style of All buttons based on UI elements
     * https://projects.invisionapp.com/d/main#/console/13013080/272556130/preview
     * :not(.showcart):not(.search):not(.nav-toggle)
     */
    .action,
    .button-base,
    body .amcompany-button.-fill.-primary,
    .button-base,
    body .amlocator-main-container .amlocator-search-container .amlocator-button.-nearby,
    body .ampickup-map-popup .amlocator-button.-pickup,
    body .ampickup-map-popup .amlocator-store-desc.-active .amlocator-button.-pickup,
    body .ampickup-store-container .ampickup-choosemap,
    body .amlocator-location-container .amlocator-button,
    body .amlocator-review-form.review-form .action.submit.primary,
    body.amcompany-customer-account .primary.action,
    body .action.tocart.primary.cti-login,
    body.amcompany-customer-account .primary.action,
    .xtento-hideprice-add_to_cart-button,
    .modal-footer > button {
        @extend %button-base;
        @extend %u-bg-color-transition;

        &:active {

            &:after,
            > span {
                position: relative;
                top: 2px;
            }
        }
    }

    .button-promo,
    .action.promo {
        @extend %button-cheer;
    }

    .button-yell,
    .contact-index-index .form.contact .action.submit,
    .checkout-onepage-success .checkout-success-block .action.primary,
    .forget .action.primary,
    .password.reset .action.primary,
    .action.create,
    .form-create-account .action.submit,
    .modal-footer .action-save-address,
    .block-wishlist .action.details,
    .button.action.continue.primary,
    .form-orders-search .action.search,
    .action.login,
    body .amcompany-button.-fill.-primary,
    .action.login,
    body .amlocator-main-container .amlocator-search-container .amlocator-button.-nearby,
    body .amlocator-button.amlocator-filter-attribute,
    body .ampickup-store-container .ampickup-choosemap,
    body .amlocator-location-container .amlocator-button,
    body .amlocator-review-form.review-form .action.submit.primary,
    body .action.subscribe.primary,
    body .amblog-grid-list>.amblog-item .amblog-read {
        @extend %button-cheer;
    }

    .action.reload,
    .action.remind {
        @include animated-underline($text-muted);
        width: auto;
        padding: 0;
        color: $text-muted;
    }

    .button-whisper,
    .action.order,
    .action.track,
    .action.print,
    .action.view,
    .action.edit,
    .action.more,
    .action.delete,
    .action.apply,
    .giftcard .action.check,
    .action.multicheckout,
    .action.change-password,
    body .amlocator-filters-container .amlocator-clear,
    body.amcompany-customer-account .action.view {
        @extend %button-whisper;
    }

    .button-shout,
    .action.add,
    .action-primary.action-accept,
    .action.checkout,
    .form-wishlist-items > .actions-toolbar .action.tocart,
    .wishlist.share .action.primary,
    .action.action-update,
    .action.save,
    body .ampickup-map-popup .amlocator-button.-pickup,
    body .ampickup-map-popup .amlocator-store-desc.-active .amlocator-button.-pickup,
    body .amblog-form-search .amblog-btn,
    body .amblog-post-container .amblog-read {
        @extend %button-cheer;
    }

    .button-cheer,
    .checkout-container .action.continue,
    .action.action-gift,
    .amcform-submit.action.submit.primary,
    .amcform-next.action.submit.primary,
    .xtento-hideprice-add_to_cart-button,
    .modal-footer .confirm-modal,
    body #checkout-step-shipping .action-show-popup,
    body .action.primary.tocart,
    body .action.tocart.primary.cti-login {
        @extend %button-cheer;
    }

    .button-cheer-inverted,
    .product-item-actions .action.tocart,
    .form-orders-search .submit,
    .action.primary.customize,
    .product-item-inner .action.tocart,
    .amcform-prev.action.submit.primary,
    body.amcompany-customer-account .primary.action,
    .modal-footer .close-modal {
        @extend %button-cheer-inverted;
    }

    .button-murmur,
    .form-wishlist-items > .actions-toolbar .action.update,
    .form-wishlist-items > .actions-toolbar .action.share,
    .action.viewcart,
    .action-secondary.action-dismiss,
    .checkout-success-block .action.continue,
    .checkout-success-block .action.print,
    .action.action-cancel,
    .modal-footer .action-hide-popup,
    .action.back,
    .action.cancel,
    .btn.btn-outline-primary {
        @extend %button-murmur;
    }

    .checkout-success-block .action.primary,
    .button-whistle {
        @extend %button-whisper;
    }

    .action.checkout {
        text-transform: uppercase;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;

        @include respond-to-up($tweakpoint-nav-toggle) {
            width: auto;
        }
    }

    .action.primary.signup {
        display: inline-block;
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        > button {
            margin-bottom: 1rem;
            width: 100%;

            @include respond-to-up(smm) {
                margin-bottom: 0;
                width: auto;
                margin-left: 1rem;
            }
        }
    }
    .action.primary.signup {
        display: inline-block;
    }

    body.catalog-product-view .xtento-hideprice-add_to_cart-button {
        width: 100%;
        box-sizing: border-box;
        justify-content: center;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        max-width: none;
    }

    body .products-grid .product-item .action.tocart.cti-login,
    body .products-list .product-item .action.tocart.cti-login {
        margin: 0 0 1rem 0;
        max-width: none;
        width: 100%;
        font-size: 20px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .pagebuilder-button-primary,
    .pagebuilder-button-secondary,
    .pagebuilder-button-link {
        @extend %button-base;
        @extend %u-bg-color-transition;

        @include respond-to-down(smm) {
            margin-bottom: 1rem;
            width: auto;
        }
    }

    .pagebuilder-button-primary {
        @extend %button-cheer;
    }

    .pagebuilder-button-secondary {
        @extend %button-cheer-inverted;
    }

    .pagebuilder-button-link {
        @extend %button-whisper;
    }

    .pagebuilder-button-primary.pagebuilder-slide-button {
        @extend %button-cheer-with-icon;

        &:before {
            display: none;
        }
    }
}

#product-addtocart-button,
body.catalog-product-view .box-tocart .action.primary.tocart,
body.catalog-product-view .xtento-hideprice-add_to_cart-button {
    font-size: 20px;

    @include respond-to-up($tweakpoint-nav-toggle) {
        font-size: 32px;
        line-height: 38px;
    }

    > span {
        overflow: unset;
    }
}

#product-addtocart-button:hover {
    background-color: darken((map_get($theme-colors, 'primary')), 10%);
}

body.catalog-product-view .xtento-hideprice {
    width: 100% !important;
}
