.table-wrapper {
    margin-top: spacers(3);
    margin-bottom: spacers(3);
}

body .product.attribute.description {
    table {
        display: table;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        box-sizing: border-box;
        text-indent: initial;

        tbody {
            display: table-row-group;
            vertical-align: middle;
            border-color: inherit;
        }

        th {
            padding-left:5px;
        }

        tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;

            &:first-of-type {
                font-weight: bold;
                vertical-align: middle;

                > td {
                    background-color: lighten((map-get($theme-colors, 'light')), 3%);
                }
            }
        }

        td {
            display: table-cell;
            vertical-align: inherit;
            padding: .5rem;
            text-align: left !important;
        }
    }

    table, th, td {
        border: 1px solid $border-color;
    }
}

