.actions-toolbar {
  @extend %u-flex-col-xcenter-ycenter;
  flex-wrap: wrap;

  @include respond-to-up(md) {
    flex-direction: row;
  }

  .primary,
  .secondary {
    width: 100%;
    margin-bottom: $input-margin-y;

    @include respond-to-up(md) {
      max-width: 100%;
      width: auto;
      margin-bottom: 0;
    }

    .action {
      @include respond-to-down(md, $breakpoints, true) {
        margin: 0 auto;
      }
    }
  }

  .secondary {
    @include respond-to-up(md) {
      justify-self: flex-end;
      align-items: flex-end;
      flex: 1 1 auto;
      text-align: right;

      > a {
        display: inline-block;
      }
    }
  }
}
