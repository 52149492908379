@mixin scrim($direction: 'bottom', $color: #111111, $after: true){
  $placement: 'after';
  @if ($after == false) {
    $placement: 'before';
  }

  $gradient-angle: -180deg;

  @if ($direction == 'top') {
    $gradient-angle: 0deg;
  }  @else if ($direction == 'left') {
    $gradient-angle: 270deg;
  }  @else if ($direction == 'right') {
    $gradient-angle: 90deg;
  }

  position: relative;

  &:#{$placement} {
    display: block;
    content: '';
    background: linear-gradient($gradient-angle, #ffffff 0%, $color 100%);
    mix-blend-mode: multiply;
    opacity: $scrim-opacity;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

