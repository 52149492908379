/**
 * General Headings
 */

.h1,
h1 {
  @extend %u-heading-style;
  @extend %u-headings-margin-bottom;
  @include fluid-type('h1');
}

.h2,
h2 {
  @extend %u-heading-style;
  @extend %u-headings-margin-bottom;
  @include fluid-type('h2');
}

.h3,
h3 {
  @extend %u-heading-style;
  @extend %u-headings-margin-bottom;
  @include fluid-type('h3');
}

.h4,
h4 {
  @extend %u-heading-style;
  @extend %u-headings-margin-bottom;
  @include fluid-type('h4');
}

.h5,
h5 {
  @extend %u-heading-style;
  @extend %u-headings-margin-bottom;
  @include fluid-type('h4');
}

.h6,
h6 {
  @extend %u-heading-style;
  @extend %u-headings-margin-bottom;
  @include fluid-type('h4');
}

%hero-title {
  @include fluid-type('hero');
  font-weight: $headings-style-weight;
  letter-spacing: $headings-style-letter-spacing;
  text-transform: $headings-style-transform;
  line-height: $headings-line-height;
}

.hero-title {
  @extend %hero-title;
}