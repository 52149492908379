/* asterisk styles for forms */
body .fl-wrap.required:before,
body .field.choice.required:before {
    opacity: 1 !important;
    content: "*";
    display: block;
    position: absolute;
    top: 1px;
    right: 18px;
    font-size: 16px;
    line-height: 1.75;
    color: map-get($theme-colors, 'negative');
    transition: all .195s cubic-bezier(.4,0,.6,1);
    padding: 12px 0 0;
    z-index: 1;
}

/* alignment of asterisk for only checkboxes */
body .field.choice.required {
    position: relative;

    &:before {
        padding: 0;
        top: 3px;
    }
}

/* remove max-width from telephone field */
body .amform-form .fb-number {
    max-width: none;
}

/* remove opacity 0 from asterisks on select dropdown fields */
body .fl-form .field._required .fl-wrap.fl-is-active:before,
body .fl-form .field._required .fl-wrap.fl-wrap-select:before,
body .fl-form .field.required .fl-wrap.fl-is-active:before {
    opacity: 1;
}

/* colour the dot within the selected radio */
body .filter-options input[type=radio]:after,
body .radio input[type=radio]:after,
body input[type=radio].radio:after {
    background-color: map-get($theme-colors, 'primary');
}

/* colour the tick within the selected checbox */
body .choice input[type=checkbox]:checked+label:before,
body .filter-options input[type=checkbox]:checked+label:before {
    color: map-get($theme-colors, 'primary');
}

body {
    .field {
        &.field-name-firstname > .label {
            position: relative;
            display: block;
            //white-space: nowrap;
            //overflow: hidden;
            //text-overflow: ellipsis;
            font-weight: 700;
            text-transform: capitalize;

            @include respond-to-up(smm) {
                display: inline-block;
                margin-bottom: 0;
                margin-right: 0;
                width: 100%;
                padding-right: 1rem;
                max-width: 180px;
                vertical-align: middle;

                &:after {
                    display: inline;
                    content: ":";
                }
            }
        }
    }
    .control {
        position: relative;

        > .label {
            position: relative;
            display: block;
            //white-space: nowrap;
            //overflow: hidden;
            //text-overflow: ellipsis;
            font-weight: 700;
            text-transform: capitalize;

            @include respond-to-up(smm) {
                display: inline-block;
                margin-bottom: 0;
                margin-right: 0;
                width: 100%;
                padding-right: 1rem;
                max-width: 180px;
                vertical-align: middle;

                &:after {
                    display: inline;
                    content: ":";
                }
            }
        }

        input#street_1 {
            margin-bottom: 1.5rem;
        }

        >input[type=datetime-local],
        >input[type=email],
        >input[type=file],
        >input[type=number]:not(.qty),
        >input[type=password],
        >input[type=tel],
        >input[type=text],
        >input[type=time],
        >select,
        >textarea,
        #start_date,
        #end_date {
            width: calc(100% - 1.3rem);

            @include respond-to-up(smm) {
                width: calc(100% - 180px - 1.3rem);
                display: inline-block;
            }
        }
    }

    input[type=number].qty,
    .block-minicart .minicart-items .product-item-pricing .details-qty input {
        width: auto;
        max-width: 85px;
    }

    .details-qty.qty,
    .field.qty .control {
        .label {
            min-width: 0;
            display: inline-block;
            margin-bottom: 0;
            padding-right: 1rem;

            @include respond-to-up(smm) {
                min-width: 0;
                width: auto;
            }

            &:after {
                display: inline;
                content: ":";
            }
        }
    }

    div.mage-error {
        font-size: 100%;

        @include respond-to-up(smm) {
            padding-left: calc(180px + 1rem);
            max-width: 100%;
        }
    }

    .ui-datepicker-trigger {
        position: absolute;
        top: 46px;
        right: 32px;

        @include respond-to-up(smm) {
            top: 13px;
            right: 33px;
        }
    }

    .rental-system-wrapper .field.field-end_date .ui-datepicker-trigger,
    .rental-system-wrapper .field.field-start_date .ui-datepicker-trigger {
        color: $black;

        &:hover {
            color: map-get($colors, 'light-grey');
        }
    }

    /* fix styles override for coupon in cart page */
    .fieldset.coupon .control {
        .label {
            width: 100%;
            display: block;
            max-width: none;
            padding-right: 0;
            margin-bottom: .5rem;
        }

        input.input-text {
            width: 100%;
        }

        div.mage-error {
            padding-left: 0;
        }
    }

    /* textarea */
    .control>textarea {
        border-radius: 0;
        border-width: 2px;
        border-color: $black;
        padding: .75rem 1.5rem;
        vertical-align: middle;
        width: calc(100% - 1.3rem);
    }

    /* required field */
    .field.required,
    .field._required {
        .control {
            &:after {
                display: inline;
                content: "\002A";
                font-size: 25px;
                color: map-get($theme-colors, 'negative');
                position: absolute;
                top: 30px;
                right: 0;

                @include respond-to-up(smm) {
                    top: 0;
                }
            }
        }
    }

    .choice input[type=checkbox]+label:before, .filter-options input[type=checkbox]+label:before {
        border-width: $input-border-width;
    }

    #shipping-new-address-form .field,
    .field.amform-layout-one,
    .field.amform-layout-two,
    .field.amform-layout-three {
        div.mage-error {
            padding-left: 0;
        }

        .label {
            font-weight: bold;

            @include respond-to-up(smm) {
                display: inline-block;
                width: 180px;
                margin-right: 1rem;

                &:after {
                    display: inline;
                    content: ":";
                }
            }

            .required,
            ._required {
                display: none;
            }
        }

        .control {
            @include respond-to-up(smm) {
                width: calc(100% - 180px - 1.3rem);
                display: inline-block;
                vertical-align: middle;
            }

            .ui-datepicker-trigger {
                position: absolute;
                margin: 0;
            }
        }

        select {
            appearance: none;
            background: #fff url(../images/select-arrow.svg) no-repeat 100% 45%;
            background-size: 50px 50px;
            padding-right: 50px;
            text-indent: .01em;
            box-sizing: border-box;
            vertical-align: baseline;
        }

        input:not(.radio):not(.checkbox),
        select {
            border-width: $input-border-width;
            border-radius: $input-border-radius;
            border-color: $input-border-color;
            padding: .75rem 1.5rem;

            @include respond-to-up(smm) {
                width: calc(100% - 1.3rem);
            }
        }

        &.required:before,
        &._required:before {
            display: none;
        }
    }
}

/* fix checkout shipping address form fields */
body.checkout-index-index .fieldset.address .field.street.admin__control-fields.required>.control {
    width: 100% !important;
}


/* fix date picker ui modal for mobile */
body .ui-datepicker {
    @include respond-to-down(smm) {
        max-width: 90%;
    }
}
body .ui-datepicker table.ui-datepicker-calendar {
    @include respond-to-down(smm) {
        table-layout: fixed;
    }
}
body .ui-datepicker-calendar th {
    @include respond-to-down(smm) {
        font-size: 10px;
    }
}
