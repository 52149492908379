body .footer.content.lazybg {

    > .newsletter {
        background-image: url('../images/footer-bg-sm.jpg');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 380px;

        @include respond-to-up(sm) {
            background-image: url('../images/footer-bg-md.jpg');
        }

        @include respond-to-up(lg) {
            background-image: url('../images/footer-bg-lg.jpg');
        }

        .form .field.newsletter label {
            z-index: 2;
        }
    }
}

@mixin follow-us($display) {
    [data-content-type="heading"]:not(.footer-nav-heading) {
        display: $display;

        + [data-content-type="image"] {
            display: $display;
        }
    }
}

body .page-footer {
    overflow: hidden;
}
/* extra padding to taking into account the live chat feature wich is fixed to the bottom */
body .footer.content {
    padding-left: 1rem;
    padding-right: 1rem;

    @include respond-to-down(md, $breakpoints, true) {
        padding-bottom: 6rem;
    }
}

body .footer.links {
    display: block;
    margin: 0;
    padding: $footer-content-padding-mobile 0 0;

    @include respond-to-up(md) {
        padding-top: $footer-content-padding-desktop;
        padding-bottom: 0;
    }

    > .pagebuilder-column-group {
        width: 100%;

        > .pagebuilder-column {
            &:first-child {
                @include respond-to-down(md, $breakpoints, true) {
                    @include follow-us(none);
                }

                @include respond-to-up(md) {
                    [data-content-type="heading"] {
                        margin-bottom: 0;
                    }
                }
            }
            &:last-child {
                @include respond-to-up(md) {
                    @include follow-us(none);
                }

                @include respond-to-down(md, $breakpoints, true){
                    [data-content-type="image"] {
                        position: absolute;
                        bottom: -2px;
                        right: 11px;

                        img {
                            max-height: 25px;
                        }
                    }
                }
            }

            ul > li {
                margin-bottom: 1rem;
            }

            ul > li > a {
                color: $white;
            }
        }
    }

    .footer-nav-heading {
        @include respond-to-down(md, $breakpoints, true) {
            padding-left: 0;

            &:before {
                font-size: 25px;
                top: -4px;
            }
        }
    }

    [data-content-type="heading"] {
        font-size: calc(10px + .69444vw);
        padding: 1rem 1.5rem 1rem 0;
        margin-bottom: 1.5rem;

        @include respond-to-down(md, $breakpoints, true) {
            font-size: 20px;
            padding-top: .5rem;
            padding-bottom: .5rem;
            margin-bottom: 0;
        }
    }

    .nav.heading {

        @include respond-to-down(md, $breakpoints, true) {
            position: relative;

            ul {
                height: 0;
                visibility: hidden;
                opacity: 0;
            }

            [data-content-type="text"] {
                height: 0;
            }

            &.active-footer-list {
                ul {
                    height: auto;
                    visibility: visible;
                    opacity: 1;
                    margin-top: 1rem;
                    margin-bottom: 2rem;
                }

                .footer-nav-heading:before {
                    content: map-get($ms-icons, 'minus');
                }

                [data-content-type="text"] {
                    height: auto;
                }
            }

            &:last-child {
                [data-content-type="heading"]:last-of-type {
                    margin-top: 1rem;
                }
            }
        }

        [data-content-type="image"] {
            max-width: 113px;

            @include respond-to-up($tweakpoint-nav-toggle) {
                max-width: 200px;
            }
        }
    }
}

/*
.footer.links {
    @extend %u-list-unstyled;
    @extend %u-flex-row-xcenter-ycenter;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: $footer-links-max-width;
    margin: 0 auto;
    padding-top: $footer-content-padding-mobile;

    @include respond-to-up(md) {
        justify-content: space-between;
        padding-top: $footer-content-padding-desktop;
    }

    ul {
        @extend %u-list-unstyled;
        height: 100%;
    }

    .footer-nav-heading {
        @extend %u-heading-style;
        @extend %ms-icon-align-right;
        @include ms-icon($footer-heading-icon, "xs");
        @include fluid-type($footer-heading-fluid-size-mobile);
        display: block;
        font-family: $font-family-heading;
        color: $footer-links-color;
        padding: $footer-heading-padding;

        @include respond-to-up($footer-accordion-breakpoint) {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
            @include fluid-type($footer-heading-fluid-size-desktop);
        }

        &:before {
            @extend %u-transform-transition;
            transition-duration: $transition-duration-ease-out;
            transition-timing-function: $transition-timing-sharp;
            transition-delay: 0.05s;
            transform-origin: 50% 50%;
            right: 10px;

            @include respond-to-up($footer-accordion-breakpoint) {
                display: none;
            }
        }

        &[href] {
            text-decoration: none;
        }
    }

    .footer-logo {
        display: none;
        @include respond-to-up($footer-accordion-breakpoint) {
            display: block;
        }
        @include respond-to-between($footer-accordion-breakpoint, lg) {
            margin-right: $footer-content-padding-desktop;
        }
    }

    .item {
        padding: $footer-links-item-padding;

        @include respond-to-up($footer-accordion-breakpoint) {
            padding: 0.5rem 0;
        }

        > .widget a,
        > a,
        > span {
            @include animated-underline($footer-links-color);
            font-size: $footer-links-font-size;
            color: $footer-heading-color;
        }
    }

    > li {
        width: 100%;
        padding: 0;

        @include respond-to-up($footer-accordion-breakpoint) {
            width: auto;
            flex: 0 1 auto;
            padding: $footer-links-item-padding;
            padding-top: 0;
            padding-bottom: 0;
        }

        &:first-child {
            @if ($footer-columns == 4) {
                order: 1;
                @include respond-to-up($footer-accordion-breakpoint) {
                    order: 0;
                }
                @include respond-to-between($footer-accordion-breakpoint, lg) {
                    flex: 0 1 100%;
                    display: flex;
                    align-items: center;
                    margin-bottom: $footer-content-padding-desktop/2;
                }
            }
        }
    }

    .heading:not(:first-child) {
        > ul {
            @extend %u-max-height-transition;
            max-height: 0;
            overflow: hidden;

            @include respond-to-up($footer-accordion-breakpoint) {
                max-height: none;
                overflow: visible;
            }

            > li:last-child {
                @include respond-to-up($footer-accordion-breakpoint) {
                    padding-bottom: 0;
                }
            }
        }

        &.is-active > ul {
            max-height: 250px;
            overflow: auto;
        }
    }

    .is-active {
        .footer-nav-heading {
            &:before {
                content: map-get($ms-icons, 'minus');
            }
        }
    }
}

 */
