/* login - registration - forgotpassword pages */
body .block-authentication,
body .login-container {
    .block-new-customer,
    .block-new-customer .block-content > p {
        color: $white;
    }
}

body .customer-account-layout .page-title-wrapper,
body .form.password.forget {
    max-width: 700px;
    margin: 0 auto 10px;
    width: 100%;
}

body .login-container .block-customer-login .block-title,
body .login-container .block-customer-login .block-content {
    width: 100%;
    margin: 0 auto 10px;
    max-width: 700px;
}

body .block-authentication .block-new-customer:before,
body .customer-account-layout-col2:before,
body .login-container .block-new-customer:before {
    color: map-get($theme-colors, 'dark');
}

/* fixes some product image issues - including checkout thumbnail product image */
body img {
    max-width: 100%;
    height: auto;
}

/* fix for messages close icon appearing at start of page load */
.global-messages:empty ~ .message-close { display:none; }


/* remove extra padding from product list on homepage */
body.cms-home.cms-index-index .block-products-list {
    &.block-widget--light {
        background: map-get($theme-colors, 'light');
    }
}

/* apply some spacing within the carousel items so that they appear separated */
body .block.block-widget .products-grid .slick-slider .product-item,
body .block.crosssell .products-grid .slick-slider .product-item,
body .block.related .products-grid .slick-slider .product-item,
body .block.upsell .products-grid .slick-slider .product-item {
    padding: .5rem;
}
